import * as React from 'react'
import DefaultLayout from "../layouts/default";
import {graphql, Page} from "gatsby";
import ReactMarkdown from 'react-markdown';
import {Helmet} from 'react-helmet';
import {getDefaultTitle} from "../logic/seo";

export default class IndexPage extends React.Component<Page, { props }> {

    public render() {
        return (

            <DefaultLayout isIndex={true}>
                <Helmet>
                    <title>{getDefaultTitle("Lieferfristen für Weihnachten zusammengefasst")}</title>
                    <meta name="description" content={this.props.data.strapiStaticPages.metaDescription}/>
                </Helmet>
                <ReactMarkdown>{this.props.data.strapiStaticPages.text}</ReactMarkdown>

            </DefaultLayout>

        )
    }
}

export const query = graphql`
query home {
  strapiStaticPages(identifier: {eq: "Home"}) {
    id
    text
    metaDescription
  }
}
`
